import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-accueil',
    templateUrl: './accueil.component.html',
    styleUrls: ['./accueil.component.scss'],
    standalone: false
})
export class AccueilComponent implements OnInit {
  buildHash = environment.buildHash;
  cplcAge = new Date().getFullYear() - 1995;

  constructor(private titleService: Title) {
    this.titleService.setTitle('Accueil');
  }

  ngOnInit(): void {}
}
